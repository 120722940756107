import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as Sentry from '@sentry/angular';

@Injectable({
  providedIn: 'root',
})
export class SentryService {
  sentryInit(): void {
    // TODO: zmienić warunek poniżej przed wejściem na proda
    if (!environment.production) {
      Sentry.init({
        environment: environment.appVersion,
        dsn: environment.sentryDsn,
        transportOptions: {
          headers: {
            Authorization: 'Basic dsa',
          },
        },
        // tunnel: 'https://api-dev3.sembot.ovh/api/v1/proxy/sentry/frontend',
        // TODO: zmienić na 0.1 przed wejściem na proda
        replaysSessionSampleRate: 1.0,
        replaysOnErrorSampleRate: 1.0,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
            networkCaptureBodies: true,
            mutationLimit: 5000,
            // max 15 minutes
            maxReplayDuration: 900000,
            networkDetailAllowUrls: [environment.apiUrl],
            networkRequestHeaders: ['account', 'user-agent'],
            networkResponseHeaders: ['X-Custom-Header'],
            networkDetailDenyUrls: [environment.apiUrl + '/login', environment.apiUrl + '/password', environment.apiUrl + '/register'],
          }),
        ],
        tracesSampleRate: 1.0,
      });
    }
  }
}
