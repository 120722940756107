/* eslint-disable max-len */
import { environment } from '../../environments/environment';

const { apiUrl } = environment;

class ApiUrls {
  accountTypes = `${apiUrl}/account-types`;

  aiProjectProductGenerate = `${apiUrl}/ai/projects/{project}/products/{product}/generate`;

  aiAssistantChat = `${apiUrl}/ai/chat/project/{project}/thread/{thread}`;
  aiAssistantStarterSuggestions = `${apiUrl}/ai/chat/project/{project}/get-starter-suggestions`;
  aiAssistantClearChat = `${apiUrl}/ai/clear-chat`;
  aiAssistantNewThread = `${apiUrl}/ai/chat/project/{project}/new`;
  aiAssistantGetThreads = `${apiUrl}/ai/chat/list-threads`;
  aiAssistantGetMessages = `${apiUrl}/ai/chat/thread/{thread}/get-messages`;
  aiAssistantDeleteThread = `${apiUrl}/ai/chat/thread/{thread}/delete`;
  aiAssistantUpdateThreadTitle = `${apiUrl}/ai/chat/thread/{thread}/update-title`;
  aiAssistantSetThreadFavorite = `${apiUrl}/ai/chat/thread/{thread}/set-favorite`;
  aiAssistantAddMessageToThread = `${apiUrl}/ai/chat/thread/{thread}/add-message`;

  changelog = `${apiUrl}/changelog`;

  checkMaintenance = `${apiUrl}/check-maintenance`;

  comparators = `${apiUrl}/comparisons`;
  // comparatorsShopRequest = `${apiUrl}/comparision/shop-request`; // Unused
  comparator = `${apiUrl}/comparisons/{comparator}`;
  comparatorConfig = `${apiUrl}/comparisons/{comparator}/config`;
  comparatorContactMessage = `${apiUrl}/comparisons/{comparator}/contact-messages`;
  comparatorProjects = `${apiUrl}/comparisons/{comparator}/projects`;
  comparatorProjectsToAppend = `${apiUrl}/comparisons/{comparator}/projects-to-append`;
  comparatorAttachProjects = `${apiUrl}/comparisons/{comparator}/attach/projects`;
  // comparatorProjectsDetach = `${apiUrl}/comparators/{comparator}/projects/detach`; //usunsed
  comparatorProject = `${apiUrl}/comparisons/{comparator}/projects/{project}`;
  comparatorSearch = `${apiUrl}/comparisons/search`;
  comparatorDownloadCSV = `${apiUrl}/comparisons/{comparator}/generate-csv`;

  connection = `${apiUrl}/connections/{connection}`;
  connections = `${apiUrl}/connections`;
  connectionsServiceConnections = `${apiUrl}/connections/services/{service}/connections`;
  connectionsServices = `${apiUrl}/connections/services`;
  connectionsServicesAdCustomers = `${apiUrl}/connections/{connection}/services/ad/customers`;
  connectionsServicesAnalytics4Accounts = `${apiUrl}/connections/{connection}/services/analytics4/accounts`;
  connectionsServicesAnalytics4Properties = `${apiUrl}/connections/{connection}/services/analytics4/properties`;
  connectionsServicesAnalyticsAccounts = `${apiUrl}/connections/{connection}/services/analytics/accounts`;
  connectionsServicesAnalyticsProfiles = `${apiUrl}/connections/{connection}/services/analytics/profiles`;
  connectionsServicesAnalyticsProperties = `${apiUrl}/connections/{connection}/services/analytics/properties`;
  connectionsServicesGscSites = `${apiUrl}/connections/{connection}/services/gsc/sites`;
  connectionsShopifyConnect = `${apiUrl}/connections/shopify/connect`;
  connectionsUrl = `${apiUrl}/connections/url`;

  bingCustomer = `${apiUrl}/connections/{connection}/services/bing/customers`;
  bingAccounts = `${apiUrl}/connections/{connection}/services/bing/accounts?customerId={customer}`;

  dictsCountries = `${apiUrl}/dicts/countries`;
  dictsGoogleLanguages = `${apiUrl}/dicts/google_languages`;
  // dictsLanguages = `${apiUrl}/dicts/languages`; // Unused

  contact = `${apiUrl}/contact`;
  switchToCss = `${apiUrl}/switch-to-cssprogram`;
  emailResend = `${apiUrl}/email/resend`;
  emailVerify = `${apiUrl}/email/verify/{token}`;

  geo = `${apiUrl}/geo`;

  login = `${apiUrl}/login`;
  logout = `${apiUrl}/logout`;

  merchantCategories = `${apiUrl}/merchant/categories`;
  passwordNew = `${apiUrl}/password/new`;
  passwordReset = `${apiUrl}/password/reset`;
  passwordSendMail = `${apiUrl}/password/send-mail`;
  platformCategories = `${apiUrl}/platform-categories`;

  // productsImagesStatus = `${apiUrl}/products/images/status`; // Unused
  profile = `${apiUrl}/profile`;
  profileAccounts = `${apiUrl}/profile/accounts`;
  profileAccountsSearch = `${apiUrl}/profile/accounts/search`;
  profileSettingsActiveComparator = `${apiUrl}/profile/settings/activeComparison`;
  profileSettingsActiveProject = `${apiUrl}/profile/settings/activeProject`;
  profileSettingsLanguage = `${apiUrl}/profile/settings/language`;
  profileSettingsStarredProject = `${apiUrl}/profile/settings/starredProject/{project}`;
  profileSettingsStarredProjects = `${apiUrl}/profile/settings/starredProject`;
  profileSettingsTableStorage = `${apiUrl}/profile/settings/tableStorage`;
  profileSettingsHoliday = `${apiUrl}/profile/settings/holiday`;

  settingsSetStorage = `${apiUrl}/settings/set-option`;
  settingsGetStorage = `${apiUrl}/settings/get-option`;

  projects = `${apiUrl}/projects`;
  projectsList = `${apiUrl}/projects/list`;
  // projectsComparatorsApiList = `${apiUrl}/projects/comparators/api/list`; // Unused
  // projectsComparatorApiReport = `${apiUrl}/projects/comparators/api/{comparator}/report`; // Unused
  // projectsComparatorsLogin = `${apiUrl}/projects/comparators/login`; // Unused
  project = `${apiUrl}/projects/{project}`;
  projectType = `${apiUrl}/projects/type`;

  connectionReportGenerate = `${apiUrl}/projects/{project}/connection-reports/generate-report`;
  connectionReportDownload = `${apiUrl}/projects/{project}/connection-reports/get-report`;
  connectionReportDownloadSummary = `${apiUrl}/projects/{project}/connection-reports/get-report-summary`;
  connectionReportBatch = `${apiUrl}/projects/{project}/connection-reports/batch-generate-report`;
  reportCampaign = `${apiUrl}/projects/{project}/connection-reports/get-campaigns-list`;
  reportCampaignsPerConnection = `${apiUrl}/projects/{project}/connection-reports/campaigns-list`;
  reportCampaignClearCache = `${apiUrl}/projects/{project}/connection-reports/{hashKey}`;

  dashboardTargets = `${apiUrl}/projects/{project}/targets`;
  dashboardIndicators = `${apiUrl}/projects/{project}/connection-reports/get-customer-report`;

  projectCampaigns = `${apiUrl}/projects/{project}/campaigns`;
  projectCampaignsConnections = `${apiUrl}/projects/{project}/campaigns/connections`;
  projectCampaignsConnection = `${apiUrl}/projects/{project}/campaigns/connections/{connection}`;
  projectCampaignsRule = `${apiUrl}/projects/{project}/campaigns/rules/{rule}`;
  projectCampaignsSearch = `${apiUrl}/projects/{project}/campaigns/search`;

  projectCampaignsOutsideStart = `${apiUrl}/projects/{project}/campaigns/outside/start-campaign`;
  projectCampaignsOutsideStop = `${apiUrl}/projects/{project}/campaigns/outside/stop-campaign`;
  projectCampaignsOutsideStartAdGroup = `${apiUrl}/projects/{project}/campaigns/outside/start-ad-group`;
  projectCampaignsOutsideStopAdGroup = `${apiUrl}/projects/{project}/campaigns/outside/stop-ad-group`;

  googleAdsCampaignName = `${apiUrl}/projects/{project}/ads/google/campaigns/update/name`;
  googleAdsCampaignBudget = `${apiUrl}/projects/{project}/ads/google/campaigns/update/budget`;
  googleAdsCampaignStatus = `${apiUrl}/projects/{project}/ads/google/campaigns/update/status`;

  // projectCampaignKeywordsStore = `${apiUrl}/projects/{project}/campaigns/{campaign}/keywords_store`; // Unused
  projectCampaign = `${apiUrl}/projects/{project}/campaigns/{campaign}`;
  projectCampaignAssetCallToAction = `${apiUrl}/projects/{project}/campaigns/{campaign}/call-to-action`;
  projectCampaignAssetGroup = `${apiUrl}/projects/{project}/campaigns/{campaign}/asset-group`;
  projectCampaignAudiences = `${apiUrl}/projects/{project}/campaigns/{campaign}/audiences`;
  projectCampaignBrands = `${apiUrl}/projects/{project}/campaigns/{campaign}/brands`;
  projectCampaignBudgets = `${apiUrl}/projects/{project}/campaigns/{campaign}/budgets`;
  projectCampaignCategories = `${apiUrl}/projects/{project}/campaigns/{campaign}/categories`;
  projectCampaignFilter = `${apiUrl}/projects/{project}/campaigns/{campaign}/filter`;
  projectCampaignKeywords = `${apiUrl}/projects/{project}/campaigns/{campaign}/keywords`;
  projectCampaignKeywordsMassUpdate = `${apiUrl}/projects/{project}/campaigns/{campaign}/keywords/mass-update`;
  projectCampaignKeywordsPreview = `${apiUrl}/projects/{project}/campaigns/{campaign}/keywords_preview`;
  projectCampaignKeywordsSave = `${apiUrl}/projects/{project}/campaigns/{campaign}/keywords_store`;
  projectCampaignKeywordsStatus = `${apiUrl}/projects/{project}/campaigns/{campaign}/keywords_status`;
  projectCampaignKeywordsUploadCsv = `${apiUrl}/projects/{project}/campaigns/{campaign}/upload_csv`;
  projectCampaignKeywordsParseCsv = `${apiUrl}/projects/{project}/campaigns/{campaign}/parse_csv`;
  projectCampaignOptionsColumn = `${apiUrl}/projects/{project}/campaigns/{campaign}/options/{column}`;
  projectCampaignProducts = `${apiUrl}/projects/{project}/campaigns/{campaign}/products`;
  projectCampaignRules = `${apiUrl}/projects/{project}/campaigns/{campaign}/rules`;
  projectCampaignRulesAction = `${apiUrl}/projects/{project}/campaigns/{campaign}/rules/action`;
  projectCampaignSegmenting = `${apiUrl}/projects/{project}/campaigns/{campaign}/segmenting`;
  projectCampaignStartSync = `${apiUrl}/projects/{project}/campaigns/{campaign}/sync`;
  projectCampaignStrategy = `${apiUrl}/projects/{project}/campaigns/{campaign}/strategy`;
  projectCampaignTargeting = `${apiUrl}/projects/{project}/campaigns/{campaign}/targeting`;
  projectCampaignTemplate = `${apiUrl}/projects/{project}/campaigns/{campaign}/template`;
  projectCampaignTemplateProduct = `${apiUrl}/projects/{project}/campaigns/{campaign}/template/{product?}`;

  projectComparators = `${apiUrl}/projects/{project}/comparisons`;
  projectComparatorsConnection = `${apiUrl}/projects/{project}/connections/{connectionPivot}`;
  projectConnections = `${apiUrl}/projects/{project}/connections`;
  projectFacebookBusinnessConnections = `${apiUrl}/projects/{project}/connections/service/FACEBOOK_BUSINESS`;
  projectFeedInputSearch = `${apiUrl}/projects/{project}/feeds/input/search`;
  projectFeedInputSearchConnections = `${apiUrl}/projects/{project}/feeds/input/search-connections`;
  projectFeedsInput = `${apiUrl}/projects/{project}/feeds/input/{feed}`;
  projectFeedsInputs = `${apiUrl}/projects/{project}/feeds/input`;
  projectFeedsInputsSchedule = `${apiUrl}/projects/{project}/feeds/input/schedule`;
  projectFeedsInputsJobsParse = `${apiUrl}/projects/{project}/feeds/input/jobs/parse`;
  projectFeedsJobsCheck = `${apiUrl}/projects/{project}/feeds/jobs`;

  adAccountsForFacebookConnection = `${apiUrl}/projects/{project}/services/facebook/ad_accounts/{connection}`;
  businessesForFacebookConnection = `${apiUrl}/projects/{project}/services/facebook/businesses/{connection}`;
  facebookAdvantageObjectives = `${apiUrl}/projects/{project}/services/facebook/advantage/objectives`;
  facebookAdvantagePixelsByConnection = `${apiUrl}/projects/{project}/services/facebook/pixels/{connection}`;
  facebookCampaign = `${apiUrl}/projects/{project}/services/facebook/campaigns/{campaign}`;
  postsForFacebookConnection = `${apiUrl}/projects/{project}/services/facebook/posts/{connection}`;
  facebookTargetingCountries = `${apiUrl}/projects/{project}/services/facebook/targeting-countries`;
  fieldsForFacebookMapper = `${apiUrl}/projects/{project}/services/facebook/fields`;
  pagesForFacebookConnection = `${apiUrl}/projects/{project}/services/facebook/pages/{connection}`;

  countriesForGoogleMerchantCenterMapper = `${apiUrl}/projects/{project}/services/google-merchant-center/countries`;
  fieldsForGoogleMerchantCenterMapper = `${apiUrl}/projects/{project}/services/google-merchant-center/fields`;
  projectGoogleMerchantCenterAccountsByConnection = `${apiUrl}/projects/{project}/services/google-merchant-center/accounts/{connection}`;
  projectGoogleMerchantCenterChannels = `${apiUrl}/projects/{project}/services/google-merchant-center/channels`;
  projectGoogleMerchantCenterConnections = `${apiUrl}/projects/{project}/connections/service/GOOGLE_MERCHANT_CENTER`;

  projectConversionPhrases = `${apiUrl}/projects/{project}/conversion-phrases`;
  projectConnectionFeedsInputLookup = `${apiUrl}/projects/{project}/feeds/input/lookup/connection-feed`;
  projectConnectionFeedsInputLookupById = `${apiUrl}/projects/{project}/feeds/input/{feed}/lookup/connection-feed`;
  projectFeedLookupByUrl = `${apiUrl}/projects/{project}/feeds/input/lookup`;
  projectFeedsLookup = `${apiUrl}/projects/{project}/feeds/input/{feed}/lookup`;
  projectFeedsMapping = `${apiUrl}/projects/{project}/feeds/input/{feed}/mapping`;
  projectFeedsOutput = `${apiUrl}/projects/{project}/feeds/output`;
  projectFeedsOutputById = `${apiUrl}/projects/{project}/feeds/output/{feed}`;
  projectFeedsOutputChangeActive = `${apiUrl}/projects/{project}/feeds/output/{feed}/set-active`;
  projectFeedsOutputCountries = `${apiUrl}/feeds/output/countries`;
  projectFeedsOutputFields = `${apiUrl}/projects/{project}/feeds/output/fields`; // api/v1/feeds/output/fields
  projectFeedsOutputJobsGenerate = `${apiUrl}/projects/{project}/feeds/output/jobs/generate`;
  projectFeedsOutputSchemaById = `${apiUrl}/feeds/output/schema/{feedSchema}`;
  projectFeedsOutputSchemas = `${apiUrl}/feeds/output/schema`;
  projectFeedsOutputSearch = `${apiUrl}/projects/{project}/feeds/output/search`;
  projectFeedsOutputTags = `${apiUrl}/feeds/output/tags`;
  projectFeedsOutputproductsCount = `${apiUrl}/projects/{project}/feeds/output/products-count`;
  projectPhrases = `${apiUrl}/projects/{project}/phrases`;

  // page
  projectSitePage = `${apiUrl}/projects/{project}/pages/{page}`;
  projectSitePageGscChart = `${apiUrl}/projects/{project}/pages/{page}/gsc-chart`;
  projectSitePageIndexing = `${apiUrl}/projects/{project}/pages/{page}/indexing`;
  projectSitePageKeywordById = `${apiUrl}/projects/{project}/pages/{page}/keywords/{keyword}`;
  projectSitePageKeywords = `${apiUrl}/projects/{project}/pages/{page}/keywords`;
  projectSitePageQueries = `${apiUrl}/projects/{project}/pages/{page}/queries`;
  projectSitePageRequestCrawl = `${apiUrl}/projects/{project}/pages/{page}/request-crawl`;
  projectSitePages = `${apiUrl}/projects/{project}/pages`;
  projectSiteRequestCrawl = `${apiUrl}/projects/{project}/pages/request-crawl`;
  projectSiteSummary = `${apiUrl}/projects/{project}/summary`;

  // text assistant
  textAssistant = `${apiUrl}/text-assistant`;
  textAssistantStoreDraft = `${apiUrl}/text-assistant/store`;
  textAssistantLanguages = `${apiUrl}/text-assistant/languages`;
  textAssistantLocations = `${apiUrl}/text-assistant/locations`;
  textAssistantDevices = `${apiUrl}/text-assistant/devices`;
  textAssistantDraft = `${apiUrl}/text-assistant/{draft}`;
  textAssistantUpdatePages = `${apiUrl}/text-assistant/{draft}/update-pages`;

  // categories
  projectCategories = `${apiUrl}/projects/{project}/categories`;
  projectCategoriesSearch = `${apiUrl}/projects/{project}/categories/search`;
  projectCategoryById = `${apiUrl}/projects/{project}/categories/{category}`;
  projectCategoriesMassUpdate = `${apiUrl}/projects/{project}/categories/mass-update`;
  projectCategoriesKeywords = `${apiUrl}/projects/{project}/categories/{category}/keywords`;
  projectCategoriesKeywordById = `${apiUrl}/projects/{project}/categories/{category}/keywords/{keyword}`;

  // Assets
  projectListAssets = `${apiUrl}/projects/{project}/assets`;
  projectSingleAsset = `${apiUrl}/projects/{project}/assets/{asset}`;
  projectStoreImageAsset = `${apiUrl}/projects/{project}/assets/image`;
  projectStoreVideoAsset = `${apiUrl}/projects/{project}/assets/video`;

  // brands
  projectBrands = `${apiUrl}/projects/{project}/brands`;
  projectBrandById = `${apiUrl}/projects/{project}/brands/{brand}`;
  projectBrandsMassUpdate = `${apiUrl}/projects/{project}/brands/mass-update`;
  projectBrandKeywords = `${apiUrl}/projects/{project}/brands/{brand}/keywords`;
  projectBrandKeywordById = `${apiUrl}/projects/{project}/brands/{brand}/keywords/{keyword}`;

  projectAdditionalAttributes = `${apiUrl}/projects/{project}/products/additional-attributes`;
  projectLanguage = `${apiUrl}/dicts/project_languages`;

  projectProducts = `${apiUrl}/projects/{project}/products`;
  projectProductsBrands = `${apiUrl}/projects/{project}/products/brands`;
  projectProductsCsv = `${apiUrl}/projects/{project}/products/generate-csv`;
  projectProductsCustomLabels = `${apiUrl}/projects/{project}/products/custom-labels`;
  projectProductsFilterCopy = `${apiUrl}/projects/{project}/products/filter-copy`;
  projectProductsMassActions = `${apiUrl}/projects/{project}/products/mass-actions`;
  projectProductsMassCopy = `${apiUrl}/projects/{project}/products/mass-copy`;
  projectProductsMassUpdate = `${apiUrl}/projects/{project}/products/mass-update`;
  projectProductsOld = `${apiUrl}/projects/{project}/products/old`;
  projectProductsRemoveOverride = `${apiUrl}/projects/{project}/products/removeOverride`;
  projectProductsSearch = `${apiUrl}/projects/{project}/products/search`;
  projectProductsTypes = `${apiUrl}/projects/{project}/products/types`;

  projectProduct = `${apiUrl}/projects/{project}/products/{product}`;
  projectProductCopy = `${apiUrl}/projects/{project}/products/{product}/copy`;
  projectProductImage = `${apiUrl}/projects/{project}/products/{product}/images/{image}`;
  projectProductImageSetActive = `${apiUrl}/projects/{project}/products/{product}/images/{image}/set-active`;
  projectProductImageSetMain = `${apiUrl}/projects/{project}/products/{product}/images/{image}/set-main`;
  projectProductImages = `${apiUrl}/projects/{project}/products/{product}/images`;
  projectProductMassCopy = `${apiUrl}/projects/{project}/products/{product}/mass-copy`;
  projectProductOverride = `${apiUrl}/projects/{project}/products/{product}/override`;
  projectProductPhrases = `${apiUrl}/projects/{project}/products/{product}/phrases`;
  // projectProductServicesGoogleAnalyticsRevenue = `${apiUrl}/projects/{project}/products/{product}/services/google/analytics/revenue`; // Unused

  projectRules = `${apiUrl}/projects/{project}/rules`;
  projectRulesOrder = `${apiUrl}/projects/{project}/rules/order`;
  projectRule = `${apiUrl}/projects/{project}/rules/{rule}`;

  register = `${apiUrl}/register`;
  resources = `${apiUrl}/resources`;
  roles = `${apiUrl}/roles`;
  rolesSearch = `${apiUrl}/roles/search`;
  role = `${apiUrl}/roles/{role}`;

  users = `${apiUrl}/users`;
  bulkAdd = `${apiUrl}/users/bulk-add`;
  usersPermissions = `${apiUrl}/users/permissions`;
  usersSearch = `${apiUrl}/users/search`;
  user = `${apiUrl}/users/{user}`;

  // superuser
  superuserUserPayments = `${apiUrl}/super_admin/users/{user}/payments`;
  superuserRemoveProjectsFromComparator = `${apiUrl}/comparisons/{comparator}/detach/projects`;
  superuserChangeGlobalComparatorStateForProject = `${apiUrl}/super_admin/comparators/global/{project}`;
  superuserAddNewFeedSchema = `${apiUrl}/super_admin/feeds/output/schema`;
  superuserAddNewFeedSchemaById = `${apiUrl}/super_admin/feeds/output/schema/{feedSchema}`;
  superuserAddNewFeedSchemaSearch = `${apiUrl}/super_admin/feeds/output/schema/search`;
  superuserCurrentPackage = `${apiUrl}/payments/packages/current_su`;

  // payments
  payments = `${apiUrl}/payments`;
  paymentsAfterSubscriptionShopify = `${apiUrl}/after-subscription-charge`;
  paymentsById = `${apiUrl}/payments/{paymentId}`;
  paymentsCheckout = `${apiUrl}/payments/checkout`;
  paymentsClientSercet = `${apiUrl}/payments/client_secret`;
  paymentsCoupon = `${apiUrl}/payments/coupon`;
  paymentsCouponCheck = `${apiUrl}/payments/coupon/check/{coupon}`; // TODO: Is it needed?
  paymentsCurrentPackage = `${apiUrl}/payments/packages/current`;
  paymentsCurrentPackageShopify = `${apiUrl}/current-plugin-package`;
  paymentsGetAdditionalProducts = `${apiUrl}/payments/additional-products`;
  paymentsInvoice = `${apiUrl}/payments/invoices`;
  paymentsInvoiceData = `${apiUrl}/payments/invoices/data`;
  paymentsMethods = `${apiUrl}/payments/methods`;
  paymentsPackages = `${apiUrl}/payments/packages`;
  paymentsPackagesShopify = `${apiUrl}/plugin-subscription-plans`;
  paymentsStripeKey = `${apiUrl}/payments/stripe_key`;
  paymentsSubscriptionCancel = `${apiUrl}/payments/subscription/cancel`;
  paymentsSubscriptionChange = `${apiUrl}/payments/subscription/change`;
  paymentsSubscriptionPrice = `${apiUrl}/payments/subscription/price`;
  paymentsSubscriptionResume = `${apiUrl}/payments/subscription/resume`;
  paymentsSunscriptionBuy = `${apiUrl}/payments/subscription/buy`;
  paymentsSunscriptionGuestPrice = `${apiUrl}/payments/subscription/guest-price`;
  paymentsSunscriptionProductsBuy = `${apiUrl}/payments/subscription/products/buy`;
  paymentsSunscriptionProductsRemove = `${apiUrl}/payments/subscription/products/remove`;
  paymentsSunscriptionShopifyBuy = `${apiUrl}/recurring-application-charge`;

  bingCreateAccount = `${apiUrl}/ads/bing/projects/{project}/customer`;
  bingData = `${apiUrl}/ads/bing/projects/{project}/data`;
  bingCallback = `${apiUrl}/connections/bing/callback`;
  bingCurrencies = `${apiUrl}/dicts/bing/currencies`;
  bingLanguageTypes = `${apiUrl}/dicts/bing/language-types`;
  bingIndustries = `${apiUrl}/dicts/bing/industries`;

  shopifyAuth = `${apiUrl}/plugin-auth`;
  shopifyLogin = `${apiUrl}/plugin-login`;

  notificationsMarkAllAsSeen = `${apiUrl}/notifications/mark-all-as-seen`;
  notificationsMarkAsSeenByNotifications = `${apiUrl}/notifications/mark-as-seen/{notification}`;
  notificationsShowAll = `${apiUrl}/notifications/show-all`;
  notificationsShowNotification = `${apiUrl}/notifications/show-notification`; // SSE
  notificationsProjectList = `${apiUrl}/super_admin/project_support_users/list`;
  notificationsEnableInProject = `${apiUrl}/super_admin/project_support_users/attach`;
  notificationsDisableInProject = `${apiUrl}/super_admin/project_support_users/detach`;

  // TODO: monitors - temporarily unused
  productMonitorPla = `${apiUrl}/products/{product}/monitor/pla`;
  projectMonitorJobs = `${apiUrl}/projects/{project}/monitor/jobs`;
  projectMonitorJobsPlaCount = `${apiUrl}/projects/{project}/monitor/jobs/pla-count`;
  projectMonitorKeywords = `${apiUrl}/projects/{project}/monitor/keywords`;
  projectMonitorLangs = `${apiUrl}/projects/{project}/monitor/langs`;
  projectMonitorStats = `${apiUrl}/projects/{project}/monitor/stats`;

  // monitor
  projectMonitorBest = `${apiUrl}/projects/{project}/monitor/monitor-best`;
  projectMonitorCalculateResources = `${apiUrl}/projects/{project}/monitor/calculate-resources`;
  projectMonitorCsvResults = `${apiUrl}/projects/{project}/monitor/csv-results`;
  projectMonitorDuplicateTasks = `${apiUrl}/projects/{project}/monitor/duplicate`;
  projectMonitorLocations = `${apiUrl}/projects/{project}/monitor/locations`;
  projectMonitorMassAddFilterTasks = `${apiUrl}/projects/{project}/monitor/mass-store-filter`;
  projectMonitorMassAddTasks = `${apiUrl}/projects/{project}/monitor/mass-store`;
  projectMonitorMassRemoveFilterTasks = `${apiUrl}/projects/{project}/monitor/mass-delete-filter`;
  projectMonitorMassRemoveTasks = `${apiUrl}/projects/{project}/monitor/mass-delete`;
  projectMonitorMatchedProducts = `${apiUrl}/projects/{project}/monitor/results/product/matched`;
  projectMonitorMatchProducts = `${apiUrl}/projects/{project}/monitor/match-products`;
  projectMonitorMatchProductsResults = `${apiUrl}/projects/{project}/monitor/match-products-results`;
  projectMonitorNotMonitoredKeywords = `${apiUrl}/projects/{project}/monitor/keywords/not-monitored`;
  projectMonitorNotMonitoredProducts = `${apiUrl}/projects/{project}/monitor/products/not-monitored`;
  projectMonitorOrganicTasks = `${apiUrl}/projects/{project}/monitor/results/phrase/search`;
  projectMonitorPhrasesVisibility = `${apiUrl}/projects/{project}/monitor/phrases-visibility`;
  projectMonitorProcessCsv = `${apiUrl}/projects/{project}/monitor/csv`;
  projectMonitorProductCompetitors = `${apiUrl}/projects/{project}/monitor/products-competitors`;
  projectMonitorProductTasks = `${apiUrl}/projects/{project}/monitor/results/product/search`;
  projectMonitorProjectLocations = `${apiUrl}/projects/{project}/monitor/project-locations`;
  projectMonitorRestrictions = `${apiUrl}/projects/{project}/monitor/restrictions`;
  projectMonitorResultsPhrase = `${apiUrl}/projects/{project}/monitor/results/phrase/{task}`;
  projectMonitorResultsPhrases = `${apiUrl}/projects/{project}/monitor/results/phrases`;
  projectMonitorResultsProduct = `${apiUrl}/projects/{project}/monitor/results/product/{task}`;
  projectMonitorResultsProducts = `${apiUrl}/projects/{project}/monitor/results/products`;
  projectMonitorSetMatchedProducts = `${apiUrl}/projects/{project}/monitor/set-match-products`;
  projectMonitorTask = `${apiUrl}/projects/{project}/monitor/tasks/{task}`;
  projectMonitorTasks = `${apiUrl}/projects/{project}/monitor/tasks`;

  // audit
  projectAuditByUuidGet = `${apiUrl}/projects/{project}/audit/{uuid}`;
  projectAuditMatchedByUuidGet = `${apiUrl}/projects/{project}/audit/{uuid}/matched`;
  projectAuditGet = `${apiUrl}/projects/{project}/audit`;
  projectAuditStore = `${apiUrl}/projects/{project}/audits`;

  // tasks
  tasks = `${apiUrl}/tasks`;
  tasksById = `${apiUrl}/tasks/{taskId}`;
  tasksByIdComments = `${apiUrl}/tasks/{taskId}/comments`;
  tasksByIdCommentsById = `${apiUrl}/tasks/{taskId}/comments/{commentId}`;
  tasksContributors = `${apiUrl}/tasks/contributors`;
  tasksMessages = `${apiUrl}/tasks/my-messages`;
  tasksMessagesStatus = `${apiUrl}/tasks/my-messages/status`;
  tasksKanban = `${apiUrl}/tasks/kanban`;
  tasksSearch = `${apiUrl}/tasks/search`;
  tasksSetStatus = `${apiUrl}/tasks/{taskId}/set-status`;

  projectTaskSections = `${apiUrl}/projects/{project}/sections`;
  projectTaskSectionsById = `${apiUrl}/projects/{project}/sections/{sectionId}`;

  taskActions = `${apiUrl}/actions`;
  taskActionsById = `${apiUrl}/actions/{taskId}`;

  // tasks triggers
  projectTaskTriggerById = `${apiUrl}/projects/{project}/task-triggers/{triggerId}`;
  projectTaskTriggers = `${apiUrl}/projects/{project}/task-triggers`;
  projectTaskTriggersCampaignsList = `${apiUrl}/projects/{project}/task-triggers/get-campaigns`;
  projectTaskTriggersConnectionsList = `${apiUrl}/projects/{project}/task-triggers/get-connections`;
  projectTaskTriggersSearch = `${apiUrl}/projects/{project}/task-triggers/search`;
  projectTaskTriggersTemplatesList = `${apiUrl}/projects/{project}/task-triggers/get-templates`;
  projectTaskTriggersCreateMultiple = `${apiUrl}/projects/{project}/task-triggers/create-multiple-processes`;
  projectTaskTriggersAds = `${apiUrl}/projects/{project}/task-triggers/get-ads`;
  projectTaskTriggersAdGroups = `${apiUrl}/projects/{project}/task-triggers/get-ad-groups`;

  teamsSearch = `${apiUrl}/teams/search`;
  teamsAdd = `${apiUrl}/teams`;
  team = `${apiUrl}/teams/{team}`;

  // tasks library
  taskLibrary = `${apiUrl}/tasks_library`;
  taskLibraryById = `${apiUrl}/tasks_library/{templateId}`;
  taskLibraryImport = `${apiUrl}/tasks_library/{templateId}/import`;
  taskLibrarySearch = `${apiUrl}/tasks_library/search`;

  sendFeedback = `${apiUrl}/feedback/send`;
  generalSearch = `${apiUrl}/search`;

  clientCard = `${apiUrl}/super_admin/project/{project}/clientCard`;
  clientCardAttachments = `${apiUrl}/super_admin/project/{project}/clientCard/attachments`;

  attachments = `${apiUrl}/attachments/{uuid}`;
}

export default new ApiUrls();
