import { DOCUMENT } from '@angular/common';
import { Component, Inject, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import configuration from 'angular.json';
import cloneDeep from 'lodash/cloneDeep';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ConfigGeneratorService } from './shared/sem-table/configGenerator.service';
import { AuthService } from './shared/service/auth.service';
import { DisableStateService } from './shared/service/disabled-state.service';
import { LoaderService } from './shared/service/loader.service';
import { PluginService } from './shared/service/plugin.service';
import { WhiteLabelService } from './shared/service/whitelabel.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  private readonly authService = inject(AuthService);
  private readonly disableStateService = inject(DisableStateService);
  private readonly loaderService = inject(LoaderService);
  private readonly pluginService = inject(PluginService);
  private readonly router = inject(Router);
  private readonly titleService = inject(Title);
  private readonly whiteLabelService = inject(WhiteLabelService);
  private readonly configGeneratorService = inject(ConfigGeneratorService);

  isDisabledState$ = this.disableStateService.isDisabled$;
  isLoading = true;
  isLoading$: Observable<boolean> = this.loaderService.isLoading$;
  isLoadingTooLong = false;
  title = 'Sembot.com';

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.router.events.subscribe((e: Event) => this.navigationInterceptor(e));
    this.checkIfIsWhiteLabel();

    setTimeout(() => (this.isLoading || this.loaderService.isLoading) && (this.isLoadingTooLong = true), 15000); // 15sec.
  }

  // TODO: do usunięcia przed wdrożeniem
  test(): void {
    throw new Error('Testowy błąd Sentry');
  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: Event): void {
    if (event instanceof NavigationStart) {
      if (event.url) {
        if (this.pluginService.isPluginMode) {
          this.pluginService.addToHistory(`${event.url}`);
        }
      }
    } else if (event instanceof NavigationEnd) {
      this.checkInitFiltersStates();
      setTimeout(() => (this.isLoading = false), 500);
    }
  }

  logout() {
    this.authService.logout(true, true);
  }

  private checkIfIsWhiteLabel() {
    const actualHost = this.document.location.hostname.replace('www.', '');
    const { domain, production } = environment;

    // eslint-disable-next-line @typescript-eslint/dot-notation
    const environmentAcceptWhiteLabel = production || (environment as any)['whitelabel'];
    const hostIsNotMainDomain = !actualHost.includes(domain);

    if (environmentAcceptWhiteLabel && hostIsNotMainDomain) {
      this.enableWhiteLabel(actualHost);
    } else {
      this.titleService.setTitle(this.title);
    }
  }

  private checkInitFiltersStates() {
    const navigation = this.router.getCurrentNavigation();

    if (navigation?.extras?.state?.initFiltersParams) {
      this.configGeneratorService.routeInitFiltersParams = cloneDeep(navigation.extras.state.initFiltersParams);
      delete navigation.extras.state.initFiltersParams;
    } else {
      this.configGeneratorService.routeInitFiltersParams = null;
    }
  }

  private enableWhiteLabel(hostName: string) {
    const brand = configuration.projects.sembotAngular.architect.build.options.styles.find(
      (style) => typeof style !== 'string' && style.bundleName === hostName,
    );

    this.whiteLabelService.enable(brand as { input: string; bundleName: string; inject: boolean });
  }
}
