import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, skip, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ComparatorService } from 'src/app/dashboard/comparator/comparator.service';
import ApiUrls from '../../configs/api-urls.config';
import { ProjectService } from '../../dashboard/project/project.service';
import { ResponseV2Interface } from '../model/response.model';
import { SettingsModel } from '../model/settings.model';
import { TableConfigurationInterface } from '../sem-table/models/TableConfigurationInterface.model';
import { TranslationService } from './translation.service';
import * as Sentry from '@sentry/angular';
import { ProfileModel } from '../model/profile.model';

@Injectable({
  providedIn: 'root',
})
export class SettingsService implements OnDestroy {
  settings!: SettingsModel;
  acting_as!: string;
  onDestroy$: Subject<void> = new Subject();

  constructor(
    private comparatorService: ComparatorService,
    private http: HttpClient,
    private projectService: ProjectService,
    private translationService: TranslationService,
  ) {}

  changeLanguage(language: string): Observable<SettingsModel> {
    return this.http.post<ResponseV2Interface<SettingsModel>>(ApiUrls.profileSettingsLanguage, { language }).pipe(
      map((res) => res.data),
      tap((data) => (this.settings = data)),
    );
  }

  setActiveComparator(comparatorId: number): Observable<SettingsModel> {
    return this.http
      .post<ResponseV2Interface<SettingsModel>>(ApiUrls.profileSettingsActiveComparator, {
        active_comparator_id: comparatorId,
      })
      .pipe(
        map((res) => res.data),
        tap((data) => (this.settings = data)),
      );
  }

  setActiveProject(projectId: number): Observable<SettingsModel> {
    return this.http
      .post<ResponseV2Interface<SettingsModel>>(ApiUrls.profileSettingsActiveProject, {
        active_project_id: projectId,
      })
      .pipe(
        map((res) => res.data),
        tap((data) => (this.settings = data)),
      );
  }

  setStarredProjects(starred_project_id: number): Observable<SettingsModel> {
    return this.http
      .post<ResponseV2Interface<SettingsModel>>(ApiUrls.profileSettingsStarredProjects, {
        starred_project_id,
      })
      .pipe(
        map((res) => res.data),
        tap((data) => (this.settings = data)),
      );
  }

  unsetStarredProjects(projectId: number): Observable<SettingsModel> {
    return this.http
      .delete<ResponseV2Interface<SettingsModel>>(ApiUrls.profileSettingsStarredProject.prepareUrl({ project: projectId }))
      .pipe(
        map((res) => res.data),
        tap((data) => (this.settings = data)),
      );
  }

  setHoliday(requestData: { holidayFrom: string; holidayTo: string }) {
    return this.http
      .post<ResponseV2Interface<SettingsModel>>(ApiUrls.profileSettingsHoliday, {
        holiday_from: requestData.holidayFrom,
        holiday_to: requestData.holidayTo,
      })
      .pipe(tap(({ data }) => (this.settings = data)));
  }

  setTableStorage(tableName: string, tableConfiguration: TableConfigurationInterface[]): Observable<SettingsModel> {
    const tableStorages = (this.settings && this.settings.tableStorages) || {};
    tableStorages[tableName] = tableConfiguration;

    return this.http
      .post<ResponseV2Interface<SettingsModel>>(ApiUrls.profileSettingsTableStorage, {
        data: tableStorages,
      })
      .pipe(
        map((res) => res.data),
        tap((data) => (this.settings = data)),
      );
  }

  changeSettingsInApp(settings: SettingsModel) {
    this.settings = settings;
    const {
      activeComparator,
      activeProject,
      language,
      starredProjects,
      // tableStorages,
    } = settings;

    language && this.translationService.setCustomLang(language);

    this.stopListening();
    this.comparatorService.activeComparator$.next(activeComparator || null);
    this.projectService.activeProject$.next(activeProject || null);
    this.projectService.starredProjects$.next(starredProjects || []);
    this.startListenToChangesInSettings();
  }

  ngOnDestroy(): void {
    this.stopListening();
  }

  private startListenToChangesInSettings() {
    this.comparatorService.activeComparator$
      .pipe(
        takeUntil(this.onDestroy$),
        skip(1),
        switchMap((comparator) => this.setActiveComparator(comparator!.id)),
      )
      .subscribe();

    this.projectService.activeProject$
      .pipe(
        takeUntil(this.onDestroy$),
        skip(1),
        map((project) => (project ? project.id : null)),
        switchMap((projectId) => this.setActiveProject(projectId!)),
      )
      .subscribe();

    this.projectService.starredProjectsAdded$
      .pipe(
        takeUntil(this.onDestroy$),
        map((project) => project.id),
        switchMap((projectId) => this.setStarredProjects(projectId)),
      )
      .subscribe();

    this.projectService.starredProjectsRemoved$
      .pipe(
        takeUntil(this.onDestroy$),
        map((project) => project.id),
        switchMap((projectId) => this.unsetStarredProjects(projectId)),
      )
      .subscribe();
  }

  setSentryData(res: ProfileModel): void {
    const { user, settings } = res;

    Sentry.setUser({ id: user.id, email: user.email });
    const tags = {
      user_email: user?.email,
      user_name: user?.name,
      project_id: settings?.activeProject?.id,
      project_name: settings?.activeProject?.name,
      acting_as: this.acting_as,
    };

    Object.entries(tags).forEach(([key, value]) => {
      if (value !== undefined && value !== null && value !== '') {
        Sentry.setTag(key, value);
      }
    });
  }

  private stopListening() {
    this.onDestroy$.next();
  }
}
